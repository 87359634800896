import mst from "../assets/logo.png";

export default function Header() {
  return (
    <header> 
      <a href="/">
        <img src={mst} alt="MST" />
      </a>
      <ul>
        <li><a href="https://github.com/mse1im" target="_blank" className="github"><i></i></a> </li>
        <li><a href="https://www.linkedin.com/in/mselimturan/" target="_blank" className="in"><i></i></a></li>
      </ul>
    </header>
  );
}