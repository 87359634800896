import { useEffect, useState } from "react";

export default function Main() {
  const [showModal, setShowModal] = useState(false);

  const closeWindow = () => {
    setShowModal(!showModal);
  };

  const close = () => {
    window.history.back();
  };

  const cancel = () => {
    setShowModal(!showModal);
  };

  const goRed = () => {
    window.location.href = "/alice-wonderland";
  };

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "js/mtx.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="your-choice">
        <h1>THE CHOICE IS YOURS.</h1>
        <div className="pills">
          <button className="blue" onClick={closeWindow}>
            <i></i>
          </button>
          <button className="red" onClick={goRed}>
            <i></i>
          </button>
        </div>
        <h2>Remember, all I’m offering is the truth, nothing more.</h2>
      </div>
      {showModal && (
        <div className="overlay">
          <span>
            You take the blue pill – the story ends, you wake up in your bed and
            believe whatever you want to believe.
          </span>
          <div className="choice">
            <button className="blue" onClick={close}>
              <span>Take blue pill</span>
            </button>
            <button className="red" onClick={cancel}>
              <span>Back to my choice</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
}
