import React from "react";
import Red from "./Components/Red";
import Main from "./Components/Main";
import { Routes, Route } from "react-router-dom";

function App() {

  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/alice-wonderland" element={<Red />} />
    </Routes>
  );
}
export default App;
