import Footer from "./Footer";
import Header from "./Header";
import me from "../assets/me.png";

export default function Red() {

  return (
    <>
      <Header />
      <main>
      <figure>
        <figcaption>
          <h1>Mehmet Selim Turan</h1>
          <h2>Front-End <span>Developer</span></h2>
          <ul>
            <h3>General Info</h3>
            <li><span>Adress:</span><p>Ümraniye - Istanbul (Asia)</p></li>
            <li><span>E-mail:</span><p><a href="mailto:im@mselim.dev">im@mselim.dev</a></p></li>
            <li><span>Phone:</span><p><a href="tel:+905466652500">+90 546 665 25 00</a></p></li>
          </ul>
        </figcaption>
        <img src={me} alt="Mehmet Selim Turan" />
      </figure>
      <article>
        <h3>My Interests</h3>
        <span>Technologies I'm interested in.</span>
        <ul>
          <li className="js"><i></i></li>
          <li className="react"><i></i></li>
          <li className="nextjs"><i></i></li>
          <li className="nodejs"><i></i></li>
          <li className="css3"><i></i></li>
          <li className="html5"><i></i></li>
        </ul>
      </article>
      </main>
      <Footer />
    </>
  );
}